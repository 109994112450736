<template>
    <div class="hydropower-container">
        <EditWater
                :dialogTitle="dialogTitle"
                dialogWidth="400px"
                :dialogVisible="addRoomVisible"
                @onClose="addRoomVisible = false"
                class="comment-panel edit"
                style="--item-label: 96px;padding-bottom:0;"
        >
            <div slot="dialog-footer" class="room-form room-pos" style="margin-top:-20px">
                <template v-for="(item,index) in dialogListL">
                    <div class="field-item field-Info" :key="222 + index">
                        <!--            <label class="field-item-label" style="margin-left:30px">{{ index + 1 }}、</label>-->
                        <div class="field-item-content" style="margin: 0 auto;">
                            <div style="display: inline-block;margin-right: 20px">
                                {{item.name}}
                            </div>
                            <div style="display: inline-block">
                                {{ item.no || item.value.no }}
                            </div>
                        </div>
                    </div>
                </template>
                <div style="border-top:1px solid #E5E5E5;padding:20px">
                    <button
                            class="btn btn-primary"
                            @click="
              () => {
                addRoomVisible = false;
              }
            "
                            style="
              font-size: 24px;
              background: rgb(26, 179, 148);
              color: rgb(255, 255, 255);
              border-radius: 6px;
              padding:0 10px;
            "
                    >
                        关闭
                    </button>
                </div>
            </div>
        </EditWater>
    </div>
</template>

<script>
    import EditWater from "@/components/EditWater";

    export default {
        data() {
            return {
                addRoomVisible: false,
                dialogTitle: "电表",
                dialogListL: [],
            };
        },
        activated() {
            this.$vc.on("getHydropower", "number", (_num) => {
                console.log(_num);

                this.dialogTitle = _num.title;

                this.dialogListL = _num.tiem;
                this.addRoomVisible = true;
            });
        },
        methods: {},
        components: {
            EditWater,
        },
        mounted() {
            this.$vc.on("getHydropower", "number", (_num) => {

                this.dialogTitle = _num.title;
                this.dialogListL = _num.tiem;
                this.addRoomVisible = true;
            });
        },
    };
</script>

<style>
    .field-Info {
        display: flex;
        margin: 15px 20px;
        font-size: 24px;
        font-family: PingFang SC;
        font-weight: 400;
        line-height: 33px;
        color: #000000;
        opacity: 1;
    }

    .hydropower-container .cs-dialog-footer {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    .edit {

    }

    .hydropower-container .cs-dialog {
        margin-top: 100px !important;
    }
</style>
