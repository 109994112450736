<template>
  <div>
    <CSDialog
      dialogTitle="提示"
      dialog-header-class=" "
      dialogWidth="480px"
      dialog-confirm-btn-text="确定"
      :dialogVisible="deleteOwnerVisible"
      @onClose="deleteOwnerVisible = false"
      @onConfirm="deleteOperate"
      dialog-header-icon="icon-menua-zu13"
    >
      <div
        slot="dialog-content"
        style="padding: 20px 30px; font-size: 24px; text-align: center"
      >
        {{title}}
      </div>
    </CSDialog>
  </div>
</template>

<script>
import CSDialog from "@/components/common/CSDialog";
export default {
  components: {
    CSDialog,
  },
  data() {
    return {
      deleteOwnerInfo: {},
      deleteTenantId: null,
      deleteOwnerVisible: false,
      title:"确认删除吗?"
    };
  },
  mounted() {
    this.$vc.on(
      this.$route.path,
      "deleteTenant",
      "openOwnerModelOver",
      (tenantInfo) => {
        this.deleteTenantId = tenantInfo.id;
        this.deleteOwnerVisible = true;
        if(tenantInfo.title){
          this.title = tenantInfo.title;
        }
      }
    );
  },
  created(){
  },
  methods: {
    deleteOperate() {
      this.$vc.emit(
        this.$route.path,
        "deleteTenantOn",
        "openOwnerModelOverOn",
        {id:this.deleteTenantId}
      );
      this.deleteOwnerVisible = false;
    },
  },
};
</script>

<style>
</style>