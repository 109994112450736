<template>
  <div class="ower-container">
    <!-- 业主管理 -->
    <div class="filter-panel">
      <input
          v-model="keyword"
          class="cs-input"
          placeholder="搜索企业/个人名称"
          style="padding-left: 10px"
          type="text"
      />
      <CSSelect>
        <select v-model="waterNun">
          <option value="">水表剩余预存金额不限</option>
          <option :value="1">小于50元</option>
          <option :value="2">小于150元</option>
          <option :value="3">小于350元</option>
          <option :value="4">小于700元</option>
          <option :value="5">大于等于700</option>
        </select>
      </CSSelect>
      <CSSelect>
        <select v-model="electricityNum">
          <option value="">电表剩余预存金额不限</option>
          <option :value="1">小于50元</option>
          <option :value="2">小于150元</option>
          <option :value="3">小于350元</option>
          <option :value="4">小于700元</option>
          <option :value="5">大于等于700</option>
        </select>
      </CSSelect>
      <button
          class="btn btn-primary btn-sm select-btn"
          @click="query()"
      >
        查询
      </button>
    </div>
    <!-- 表格 -->
    <div class="result-panel">
      <CSTable :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <button
                class="btn btn-primary sticky-right"
                @click="addOwnerBtn(null)"
            >
              <svg aria-hidden="true" class="icon">
                <use xlink:href="#icon-menujiahao"></use>
              </svg>
              添加业主
            </button>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>企业/个人名称</th>
            <th>统一社会信用代码/身份证号</th>
            <th>对公账号/对私账户</th>
            <th>房间</th>
            <th>水表剩余预存金额（元）</th>
            <th>电表剩余预存金额（元）</th>
            <th>对接人</th>
            <th>性别</th>
            <th>手机号</th>
            <!--                        <th>邮箱</th>-->
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <tr v-for="tenant in tenantList" :key="tenant.id">
            <td>
              <span class="btn-active" @click="viewUserBtn(tenant)">
                {{ tenant.companyName }}</span
              >
            </td>
            <td>{{ tenant.ucsi || "-" }}</td>
            <td>{{ tenant.businessAccount || "-" }}</td>
            <td>
              <span class="btn-active" @click="seeRoomBtn(tenant)">查看</span>
            </td>
            <td v-if="tenant.waterBalance==null">
              -
            </td>
            <td v-else>
              <span class="btn-active"
                    @click="seeWaterBtn(tenant)">{{
                  tenant.waterBalance < 0 ? '欠' + tenant.waterBalance * -1 : tenant.waterBalance
                }}</span>
            </td>
            <td v-if="tenant.electricityBalance==null">
              -
            </td>
            <td v-else>
                            <span class="btn-active"
                                  @click="seeElectricityBtn(tenant)">{{
                                tenant.electricityBalance < 0 ? '欠' + tenant.electricityBalance * -1 : tenant.electricityBalance
                              }}</span>
            </td>
            <td>{{ tenant.deputyName }}</td>
            <td>{{ tenant.deputyGender || "-" }}</td>
            <td>{{ tenant.deputyPhone || "-" }}</td>
            <!--                        <td>{{ tenant.deputyEmail || "-" }}</td>-->
            <td>
              <div class="btn-group">
                <button
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-primary dropdown-toggle"
                    data-toggle="dropdown"
                    type="button"
                >
                  操作
                </button>
                <ul class="dropdown-menu" style="width: 76px">
                  <li @click="addOwnerBtn(tenant)">
                    <a>所属房间</a>
                  </li>
                  <li @click="deleteBtn(tenant)">
                    <a>删除</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </template>
      </CSTable>
      <Pagination componentName="Pagination" name="pagination"></Pagination>
    </div>
    <!-- //查看用户弹窗 -->
    <CSDialog
        :dialog-show-confirm-btn="false"
        :dialog-visible="tenantDetailModal.visible"
        class="tenant-detail-dialog userInfo"
        dialog-cancel-btn-text="关闭"
        dialog-header-class="alert-bg"
        dialog-title="查看业主信息"
        dialog-width="820px"
        style="margin-top:0px"
        @onClose="tenantDetailModal.visible = false"
    >
      <template v-slot:dialog-content>
        <div
            style="
            max-height: 550px;
            overflow-y: auto;
            font-size: 24px;
            padding: 30px;
          "
        >
          <div class="preview-tenant">
            <p class="field">
              <span class="field-label">企业/个人名称</span>
              <span class="field-content">{{
                  tenantDetailModal.tenantInfo.companyName || "-"
                }}</span>
            </p>
            <p class="field">
              <span class="field-label"> 法人/身份 </span>
              <span class="field-content">{{
                  tenantDetailModal.tenantInfo.legalEntity || "-"
                }}</span>
            </p>
            <p class="field">
              <span class="field-label" style="line-height: 1"
              >营业执照/身份证</span
              >
              <template v-if="tenantDetailModal.tenantInfo.businessLicense">
                <img
                    :src="tenantDetailModal.tenantInfo.businessLicense"
                    style="max-width: 200px"
                />
              </template>
              <template v-else>-</template>
            </p>
            <p class="field">
              <span class="field-label">统一社会信用代码/身份证号</span
              ><span class="field-content">{{
                tenantDetailModal.tenantInfo.ucsi || "-"
              }}</span>
            </p>
            <p class="field">
              <span class="field-label">对公/对私账号</span
              ><span class="field-content">{{
                tenantDetailModal.tenantInfo.businessAccount || "-"
              }}</span>
            </p>
            <p class="field">
              <span class="field-label">对接人</span>
              <span class="field-content">
                {{ tenantDetailModal.tenantInfo.deputyName || "-" }}
              </span>
            </p>
            <p class="field">
              <span class="field-label">性别</span>
              <span class="field-content">
                {{ tenantDetailModal.tenantInfo.deputyGender }}
              </span>
            </p>
            <!--                        <p class="field">
                          <span class="field-label">身份证</span
                          ><span class="field-content">
                            {{ tenantDetailModal.tenantInfo.deputyIdNo || "-" }}
  </span>
            </p>-->
            <p class="field">
              <span class="field-label">手机</span
              ><span class="field-content">
                {{ tenantDetailModal.tenantInfo.deputyPhone || "-" }}
              </span>
            </p>
            <!--                        <p class="field">
                          <span class="field-label">邮箱</span
                          ><span class="field-content">
                            {{ tenantDetailModal.tenantInfo.deputyEmail || "-" }}
  </span>
            </p>-->
            <p class="field">
              <span class="field-label">备注</span
              ><span class="field-content">
                {{ tenantDetailModal.tenantInfo.comment || "-" }}
              </span>
            </p>
          </div>
          <template
              v-if="tenantDetailModal.rooms && tenantDetailModal.rooms.length > 0"
          >
            <div class="field">
              <div class="field-label" style="line-height: 1;vertical-align: top;">所属房间</div>
              <div class="field-content" style="line-height: 1;vertical-align: top;">
                <ul class="list-group">
                  <li v-for="(room, index) in tenantDetailModal.rooms" :key="index">
                    {{ room }}
                  </li>
                </ul>
              </div>
            </div>
          </template>
        </div>
      </template>
    </CSDialog>
    <!--        注释-->
    <!-- //查看房间显示框 -->
    <GetHydropower
        componentName="GetHydropower"
        name="getHydropower"
    ></GetHydropower>
    <!-- 编辑业主信息 -->
    <EditCompanyTenantOver
        componentName="EditCompanyTenant"
        componentTitle="添加业主"
        name="EditCompanyTenant"
        notifyLoadDataComponentName="listCompanyOwner"
        ownerTypeCd="1003"
    ></EditCompanyTenantOver>
    <!-- 删除租客 -->
    <DeleteOwnerOver></DeleteOwnerOver>
  </div>
</template>

<script>
import CSTable from "@/components/common/CSTable";
import Pagination from "@/components/Pagination.vue";
import CSDialog from "@/components/common/CSDialog";
import GetHydropower from "@/components/GetHydropower";
import EditCompanyTenantOver from "@/components/EditCompanyTenantOver.vue";
import DeleteOwnerOver from "@/components/DeleteOwnerOver.vue";
import CSSelect from "@/components/common/CSSelect"
import {
  deleteTenantInfoUrl,
  queryBuildingRoomsWUrl,
  queryRenterBindRoomsUrl,
  queryRoomInfoUrl,
  queryTenantUrl,
} from "@/requestUrl";

export default {
  data() {
    return {
      roomList: [],
      filterHeight: 0,
      tenantDetailModal: {
        visible: false, //判断用户信息框是否显示
        rooms: [],
        tenantInfo: {},
      },
      keyword: "", //查询信息
      tenantList: [],
      waterNun: "",
      electricityNum: "",
      pageParams: {
        pageNo: 1,
        pageSize: 10
      }
    };
  },
  components: {
    CSTable,
    Pagination,
    CSDialog,
    GetHydropower,
    EditCompanyTenantOver,
    DeleteOwnerOver,
    CSSelect,
  },
  mounted() {
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });
    //回车查询事件
    window.addEventListener("keydown", this.queryDown);
    this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
      this.pageParams.pageNo = pageNo;
      this.query(pageNo);
    });
    this.query();
    this.$vc.on(this.$route.path, "listCompanyOwnerData", () => {
      this.query();
    });

    this.$vc.on(
        this.$route.path,
        "deleteTenantOn",
        "openOwnerModelOverOn",
        (item) => {
          this.$fly
              .post(deleteTenantInfoUrl, {
                id: item.id,
                regionId: this.$vc.getCurrentRegion().communityId,
              })
              .then((res) => {
                if (res.code != 0) {
                  return
                }
                console.log(1101);
                this.query();
                this.$vc.toast("删除成功");
              });
        }
    );
  },
  beforeDestroy() {
    //取消回车查询事件
    window.removeEventListener("keydown", this.queryDown);
  },
  deactivated() {
    window.removeEventListener("keydown", this.queryDown);
  },
  methods: {
    /**
     * 查询租客绑定的房间
     * @param {Number} lesseeId 租客ID
     *
     *  */
    queryRenterBindRooms(lesseeId) {
      return new Promise((resolve) => {
        this.$fly
            .post(queryRenterBindRoomsUrl, {
              lesseeId,
              regionId: this.$vc.getCurrentRegion().communityId,
            })
            .then((res) => {
              resolve(res);
            });
      });
    },
    //添加业主按钮
    addOwnerBtn(owner) {
      this.$vc.emit(this.$route.path, "EditCompanyTenantOver", "show", owner);
    },
    queryRoomInfoRooms(lesseeId) {
      return new Promise((resolve) => {
        this.$fly
            .get(queryRoomInfoUrl, {
              id: lesseeId,
            })
            .then((res) => {
              resolve(res);
            });
      });
    },

    //查看用户
    async viewUserBtn(tenantInfo) {
      const bindRoom = await this.queryRoomInfoRooms(tenantInfo.id);
      // const bindRoom = await this.queryRenterBindRooms(tenantInfo.id);
      let rooms = bindRoom.data;
      // bindRoom.data.contracts.forEach((contract) => {
      //     const {contractId, contractNo} = contract;
      //     contract.rooms.forEach((room) => {
      //         rooms.push({
      //             ...room,
      //             contractId,
      //             contractNo,
      //         });
      //     });
      // });
      console.log(rooms)
      this.tenantDetailModal = {
        visible: true,
        rooms,
        tenantInfo,
      };
    },
    // 查询房间列表
    async queryRoom() {
      return this.$fly
          .post(queryBuildingRoomsWUrl, {
            regionCode: this.$vc.getCurrentRegion().communityId,
            state: 1,
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.roomList = res.data;
            return res.data;
          });
    },
    //查看房间信息
    async seeRoomBtn(item) {
      let arr = [];
      console.log(item);
      const bindRoom = await this.queryRoomInfoRooms(item.id);
      bindRoom.data.forEach((room, index) => {
        arr.push({
          id: index,
          no: room
        });
      });

      this.$vc.emit("getHydropower", "number", {
        title: "查看房间",
        tiem: arr,
      });
    },
    //修改信息
    modifyBtn() {
    },
    //删除信息
    deleteBtn(item) {
      this.$vc.emit(this.$route.path, "deleteTenant", "openOwnerModelOver", {
        id: item.id,
      });
    },
    //查询信息
    query(pageN = this.pageParams.pageNo, pageS = 10) {
      this.$fly
          .post(queryTenantUrl, {
            regionId: this.$vc.getCurrentRegion().communityId,
            keyword: this.keyword,
            pageNo: pageN,
            pageSize: pageS,
            type: 1,
            waterNun: this.waterNun,
            electricityNum: this.electricityNum
          })
          .then((res) => {
            if (res.code != 0) {
              return;
            }
            this.tenantList = res.data.datas;
            if (pageN === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total || res.data.datas.length,
                pageNo: pageN,
                pageSize: pageS,
              });
            }

          });
    },
    //回车查询
    queryDown(e) {
      if (e.keyCode == 13) {
        this.query();
      }
    },
    seeWaterBtn(tenant) {
      //带过去的参数
      const params = {
        lesseeId: tenant.id,
        totalRemnant: tenant.waterBalance,
      };
      this.$router.push({
        name: "waterInfo",
        query: params,
      });
    },
    seeElectricityBtn(tenant) {
      //带过去的参数
      const params = {
        lesseeId: tenant.id,
        totalRemnant: tenant.electricityBalance,
      };
      this.$router.push({
        name: "electricityInfo",
        query: params,
      });
    }
  },
};
</script>

<style lang="stylus" scoped>
.list-group
  //margin-top -50px
  line-height 1
  list-style none
  color #000

  li:not(:last-of-type)
    margin-bottom 15px

.input-div {
  display: inline-block;
  margin-right: 30px;
}

input {
  width: 220px;
  height: 30px;
  border: 1px solid #999999;
  opacity: 1;
  border-radius: 3px;
}

.btn-active {
  color: #1dafff;
  text-decoration: underline;
  cursor: pointer;
}

.field {
  &-label {
    width: 200px;
    display: inline-block;
    margin-right: 40px;
    text-align: right;
    vertical-align: top;
  }

  &-content {
    display: inline-block;
    max-width: calc(100% - 240px);
  }
}

</style>
